/* eslint-disable jsx-a11y/control-has-associated-label */

import "./styles.scss"

import React, { useState } from "react"

import { useLocale } from "context/locale"

import IcoDownload from "assets/icons/download.svg"
import ArrowTile from "components/Icons/arrow"

const Content = ({ allResolution }) => {
  const { t } = useLocale()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [formPassword, setFormPassword] = useState(null)
  const [error, setError] = useState(null)

  const formSubmit = e => {
    e.preventDefault()
    formPassword === process.env.GATSBY_RESOLUTIONS_PASS
      ? setIsLoggedIn(true)
      : setError(t("Błędne hasło."))
  }

  return (
    <section className="resolution-content">
      <div className="container-fluid">
        {!isLoggedIn ? (
          <form onSubmit={formSubmit} className="resolution-content__form">
            <h4>{t("Treść niedostępna publicznie.")}</h4>
            <p>{t("Aby uzyskać dostęp podaj hasło")}</p>
            <div className="resolution-content__input">
              <input
                type="password"
                placeholder={`${t("hasło")}*`}
                onChange={e => {
                  setError(null)
                  setFormPassword(e.target.value)
                }}
                required
              />
              <button type="submit">
                <ArrowTile />
              </button>
            </div>
            <div className="resolution-content__error">{error}</div>
          </form>
        ) : (
          allResolution?.map((item, index) => (
            <div className="resolution-content__item" key={index}>
              <div className="row">
                <div className="col-md-2">
                  <date>
                    {item.date.split("T")[0].split("-").reverse().join(".")}
                  </date>
                </div>
                <div className="col-md-6">
                  <h4>{item.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
                <div className="col-md-4 text-right">
                  {item?.acfResolutions?.file?.localFile?.publicURL && (
                    <a
                      href={item?.acfResolutions?.file?.localFile?.publicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={IcoDownload} alt="" />
                      </span>
                      {t("zobacz dokument")}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  )
}

export default Content
