import React from "react"

const ArrowTile = () => {
  return (
    <svg
      id="Component_20_2"
      data-name="Component 20 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g
        id="Ellipse_5"
        data-name="Ellipse 5"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      >
        {/* <circle cx="24" cy="24" r="24" stroke="#000" /> */}
        <circle cx="24" cy="24" r="23.5" fill="#fff" />
      </g>
      <path
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="0.5"
        cy="0.5"
        r="0.5"
        transform="translate(24 23)"
        fill="#000"
        opacity="0"
      />
      <path
        id="Path_639"
        data-name="Path 639"
        d="M-24430-19197.334v12h12"
        transform="translate(-3679.055 -30817.699) rotate(-135)"
        fill="none"
        stroke="#000"
        strokeWidth="2"
      />
    </svg>
  )
}

export default ArrowTile
